<template>
<footer style="height:200px;">
  <div class="footer_content clearfix">
      <div class="footer_info fl" style="position:relative;">
          <div class="footer_related">
              <p>相关链接</p>
              <ul class="clearfix">
                  <li><a @click="gotoAbout('/jgyl')" target="_blank">机构养老</a></li>
                  <li><a @click="gotoAbout('/sqyl')" target="_blank">社区养老</a></li>
                  <li><a @click="gotoAbout('/jjyl')" target="_blank">居家养老</a></li>
                  <li><a @click="gotoAbout('/news/1')" target="_blank">颐悦要闻</a></li>
                  <li><a @click="gotoAbout('/news/5')" target="_blank">公司动态</a></li>
                  <li><a @click="gotoAbout('/news/6')" target="_blank">行业资讯</a></li>
                  <li><a @click="gotoAbout('/notice')" target="_blank">公示公告</a></li>
                  <li><a @click="gotoAbout('/cases/4')" target="_blank">项目展示</a></li>
                  <!-- <li><a @click="gotoAbout('/posts')" target="_blank">养老科普</a></li> -->
                  <li><a @click="gotoAbout('/about')" target="_blank">关于我们</a></li>
                  <!-- <li><a @click="gotoAbout('/contact')" target="_blank">联系我们</a></li> -->
              </ul>
          </div>
       
      </div>
      <div class="service_hotline fl" style="padding-top:40px">
          <div style="float:left">
			  <span class="hotline_word">全国统一客服热线</span><br/>
			  <span class="hotline_tel">{{ record.kftel }}</span>
		  </div>
          <div style="float:right">
			  <span class="hotline_word">投诉专线</span><br/>
			  <span class="hotline_tel">{{ record.tstel }}</span>
		  </div>
      </div>
      <div class="footer_code fr clearfix">
          <div class="code_item fl">
              <img v-if="record.image4 !== undefined" :src="record.image4.external_url" alt="">
              <span>小程序商城<br/>二维码</span>
          </div>
		  <div class="code_item fl">
		      <img v-if="record.image5 !== undefined" :src="record.image5.external_url" alt="">
		      <span>丝路邻里<br/>二维码</span>
		  </div>
          <div class="code_item fr">
              <img v-if="record.image6 !== undefined" :src="record.image6.external_url" alt="">
              <span>抖音二维码</span>
          </div>
      </div>
  </div>
  
  <div style="text-align: center; margin-bottom: 10px; font-size: 14px;margin-top: 30px;"> Copyright &copy 西安颐悦养老产业发展有限公司.All Rights Reserved.
    <a style="color:#FFF" href="http://beian.miit.gov.cn">备案号：{{ record.icpcode }}</a><a style="color:#FFF" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002002743"><img src="/uploads/10001/20240110/1d9444155cbcabdab616d0cf906fc7d7.png" style="width: 20px;">陕公网安备61019002002743号</a>
  </div>
</footer>
</template>


<script>

import * as Api from '@/api'
import $ from 'jquery'
export default {
  data() {
    return {
      recordId: null,
      htmlContent: '',
      record: {}
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      Api.setting({key:'basic'}).then(response=>{
        this.record = response.data.data.detail
        let newtitle = this.record.sitename
        // console.log(this.record)
        $(document).attr('title',newtitle);
        // let mtitle = $(document).attr('title');
        // console.log('site title:' + mtitle)
      })
    }
  }
}

</script>