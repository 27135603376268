<template>
    <div class="content" >
      <!-- banner -->
      <div class="swiper-container  clearfix">
        <div class="swiper-wrapper">
			<div  class="swiper-slide">
			  <img class="indexbg1" alt="">
			</div>
			<div  class="swiper-slide">
			  <img class="indexbg2" alt="">
			</div>
          <div class="swiper-slide">
            <img class="indexbg3" alt="">
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
      <!-- 分类 -->
      <div class="classification1" style="font-size: 30px; line-height: 3cm; color: #555; text-align: center; padding-top: 210px;">
        <p style="font-size: 30px; font-weight: bolder;">
          <span id="jgcount" style="font-size: 60px; color: #118d73;"><a @click="gotoAbout('/jgyl')"><img src="/static/img/sy1_r1_c1.png" style="width:272px; cursor: pointer;"/></a></span>
		  <img src="/static/img/sy1_r2_c3.png" style="width: 51px;padding-left:50px;"/>
          <span id="shqcount" style="font-size: 60px;padding-left: 65px;color: #118d73;"><a @click="gotoAbout('/sqyl')"><img src="/static/img/sy1_r1_c5.png" style="width:272px;cursor: pointer;"/></a></span>
		  <img src="/static/img/sy1_r2_c3.png" style="width: 51px;padding-left:50px;"/>
          <span id="jjcount" style="font-size: 60px;padding-left: 65px;color: #118d73;"><a @click="gotoAbout('/jjyl')" ><img src="/static/img/sy1_r1_c7.png" style="width:272px;cursor: pointer;"/></a></span></p>
      </div>
      <!-- 项目展示 -->
      <div class="prefecture">
        <div style="height:680px;text-align: center;">
          <img src="/static/img/sy_r4_c3.png" style="width:286px; margin-top:75px;margin-bottom: 58px;"/> 
          <ul class="list preflist" style="margin-left: 30px; margin-right:30px;top:185px; height: 410px;">
            <li class="list-right">
              <div id="imgListDiv" style="left:0px;position:relative;">
                <a class="casebox" v-for="(item,index) in cases" :key="index"  @click="handleCaseDetail(item)">
                  <img :src='item.image_url' alt="">
                  <span class="boxtitle">{{ item.title.length > 15 ? item.title.slice(0,15) + '...' : item.title  }}</span>
                </a>
                
                <div style="clear: both;"></div>
              </div>
            </li>
          </ul>
          <div style="clear: both;"></div>
		  <div style="margin-top:425px;">
			  <a @click="scrollLeft"><img src="/static/img/sy_r12_c6.png" style="width:74px;"/></a>
			  <a @click="scrollRight"><img src="/static/img/sy_r12_c9.png" style="width:74px;"/></a>
		  </div>
        </div>

		<div style="height:680px;text-align: center;">
			<img src="/static/img/sy_r8_c3.png" style="width:286px; margin-top:75px;margin-bottom: 58px;"/>
			<ul class="list preflist" style="margin-left: 30px; margin-right:30px;top:865px; height: 410px;">
			  <li class="list-right">
			    <div id="imgListDiv1" style="left:0px;position:relative;">
			      <a class="casebox" v-for="(item,index) in cases1" :key="index"  @click="handleCaseDetail(item)">
			        <img :src='item.image_url' alt="">
			        <span class="boxtitle">{{ item.title.length > 15 ? item.title.slice(0,15) + '...' : item.title  }}</span>
			      </a>
			      
			      <div style="clear: both;"></div>
			    </div>
			  </li>
			</ul>
			<div style="clear: both;"></div>
			<div style="margin-top:425px;">
			  <a @click="scrollLeft1"><img src="/static/img/sy_r12_c6.png" style="width:74px;"/></a>
			  <a @click="scrollRight1"><img src="/static/img/sy_r12_c9.png" style="width:74px;"/></a>
			</div>
		</div>
        
      </div>
      <!-- 公告专区 -->
      <div class="news" style="height:auto !important;">
          <div class="news-content">
            <div class="new-right new-left">
              <div style="text-align: center;">
				  <img src="/static/img/sy_r14_c2.png" style="width:755px; margin-top:36px;margin-bottom: 18px;"/>
			  </div>
              <ul class="clearfix">
                <li v-for="(item, index) in record.news" :key="index" >
                  <a @click="handleNewDetail(item)" target="_blank">
                    {{ item.title.length > 32 ? item.title.slice(0,32) + '...' : item.title}}
                    <p>{{ item.create_time }}</p>
                  </a>
                </li>
               
              </ul>
              <a @click="gotoAbout('/news/1')" class="new-right-more">查看更多</a>
            </div>
		<div class="new-right notice">
            <div style="text-align: center;">
			  <img src="/static/img/sy_r14_c10.png" style="width:755px; margin-top:36px;margin-bottom: 18px;"/>
            </div>
          <div >
            <ul class="clearfix">
  
              <li v-for="(item, index) in record.notice" :key="index" >
                  <a @click="handleNoticeDetail(item)" target="_blank">
                    {{ item.title.length > 32 ? item.title.slice(0,32) + '...' : item.title}}
                    <p>{{ item.create_time }}</p>
                  </a>
                </li>
            </ul>
            <a @click="gotoAbout('/notice')" class="new-right-more">查看更多</a>
          </div>

          </div>
        </div>
        </div>
    </div>
  </template>
  
  <style src="../../public/static/css/index.css" />

  <style>
  .preli{width:20px; line-height: 230px;padding: 0 11px;cursor: pointer;}
  .preli:hover{background-color: #CCC;}
  .nextli{width:20px; line-height: 230px;padding: 0 10px;float:right;cursor: pointer;}
  .nextli:hover{background-color: #CCC;}
    .swiper-slide video {
    width: 100% !important;
    height: auto !important;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .swiper-slide .shadow {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
  }
  .list li{
    float:left;
  }
  .list-right:hover{
    cursor:pointer;
  }
  .list-right .casebox:hover{
    background-color: #FFF;
  }


    #imgListDiv{width:2636px;}
    #imgListDiv1{width:2636px;}
    .list-right > div > a.btn:hover{
      background-color: #CCC;
    }
	
	.boxtitle{
		font-size:25px;font-weight: bolder; position: absolute;bottom:10px; background:url('/static/img/sy_r6_c13.png') no-repeat;
		width:500px; height: 60px;
		margin-left: -250px;
		padding-top: 15px;
		color:#000;
	}
  </style>


<script>

import * as Api from '@/api'
export default {
  data() {
    return {
      recordId: null,
      htmlContent: '',
      record: {},
      setting:{},
      cases: [],
      cases1:[],
      image1url:'',
      image2url:'',
      image3url:'',
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      Api.home().then(response=>{
        // console.log('homedata:')
        // console.log(response)
        this.record = response.data.data.home
        this.cases = this.record.cases
        this.cases1 = this.record.cases1
        this.setting = this.record.setting
        this.image1url = this.setting.image1.external_url
        this.image2url = this.setting.image2.external_url
        this.image3url = this.setting.image3.external_url
        $('.indexbg3').attr("src", this.image3url)
        $('.indexbg2').attr("src", this.image2url)
        $('.indexbg1').attr("src", this.image1url)
		let docwidth = $(document).width()-60;
		$('.preflist').css('width', docwidth+'px');
		$('.list-right').css('width', docwidth+'px');
		// $('.swiper-wrapper img').css('width', $(document).width() + 'px');
		// $('.swiper-slide').css('width', $(document).width() + 'px');
		// $('.content').css('min-width', $(document).width()+'px');
		// console.log('docw:'+$(document).width())
		// console.log('winw:'+$(window).width())
      })
    },
    handleNewDetail(item){
      this.$router.push({name: 'NewsDetail', params:{id: item.article_id}}).then(()=>{
          location.reload()
        })
    },
    handleNoticeDetail(item){
      this.$router.push({name: 'NoticeDetail', params:{id: item.article_id}}).then(()=>{
          location.reload()
        })
    },
    handleCaseDetail(item){
      this.$router.push({name: 'CasesDetail', params:{id: item.article_id}}).then(()=>{
          location.reload()
        })
    },
    scrollLeft(){
		let docwidth = $(document).width()-60;
      let divleft = $('#imgListDiv').css('left');
      var totalWidth = 0;
      $('#imgListDiv').children('.casebox').each(function() {
        var childWidth = $(this).width()+30;
		console.log(childWidth);
        totalWidth += childWidth;
      });
      let allLength = totalWidth+100;
      let divWidth = totalWidth < docwidth ? docwidth : totalWidth;
	  console.log('divWidth:' + divWidth);
      $('#imgListDiv').css('width', divWidth + 'px');
      // console.log('alllength;' + allLength);
      divleft = divleft.replace('px','');
      divleft = Number(divleft);
      divleft -= 612;
      console.log(Number(divleft) + Number(allLength));
      if(Number(divleft) + Number(allLength) > docwidth){
        $("#imgListDiv").animate({left: divleft+'px'});
        //$('#imgListDiv').css('left', divleft + 'px');
      }
    },
    scrollRight(){
		let docwidth = $(document).width()-60;
      let divleft = $('#imgListDiv').css('left');
      var totalWidth = 0;
      $('#imgListDiv').children('.casebox').each(function() {
        var childWidth = $(this).width()+30;
        totalWidth += childWidth;
      });
      let allLength = totalWidth+100;
      let divWidth = totalWidth < docwidth ? docwidth : totalWidth;
      $('#imgListDiv').css('width', divWidth + 'px');
      console.log('alllength;' + allLength);
      divleft = divleft.replace('px','');
      console.log(Number(divleft));
      if(Number(divleft) < 0){
        divleft = Number(divleft);
        divleft += 612;
        console.log(divleft)
        $("#imgListDiv").animate({left: divleft+'px'});
        // $('#imgListDiv').css('left', divleft + 'px');
      }
    },
    scrollLeft1(){
		let docwidth = $(document).width()-60;
      let divleft = $('#imgListDiv1').css('left');
      // let allLength = $('#imgListDiv1').css('width').replace('px', '');
      var totalWidth = 0;
      $('#imgListDiv1').children('.casebox').each(function() {
        var childWidth = $(this).width()+30;
        totalWidth += childWidth;
      });
      let allLength = totalWidth+100;
      let divWidth = totalWidth < docwidth ? docwidth : totalWidth;
      $('#imgListDiv1').css('width', divWidth + 'px');
      console.log('alllength;' + allLength);
      divleft = divleft.replace('px','');
      divleft = Number(divleft);
      divleft -= 612;
      console.log(Number(divleft) + Number(allLength));
      if(Number(divleft) + Number(allLength) > docwidth){
        $("#imgListDiv1").animate({left: divleft+'px'});
        // $('#imgListDiv1').css('left', divleft + 'px');
      }
    },
    scrollRight1(){
		let docwidth = $(document).width()-60;
      let divleft = $('#imgListDiv1').css('left');
      // let allLength = $('#imgListDiv1').css('width').replace('px', '');
      var totalWidth = 0;
      $('#imgListDiv1').children('.casebox').each(function() {
        var childWidth = $(this).width()+30;
        totalWidth += childWidth;
      });
      let allLength = totalWidth+100;
      let divWidth = totalWidth < docwidth ? docwidth : totalWidth;
      $('#imgListDiv1').css('width', divWidth + 'px');
      console.log('alllength;' + allLength);
      divleft = divleft.replace('px','');
      console.log(Number(divleft));
      if(Number(divleft) < 0){
        divleft = Number(divleft);
        divleft += 612;
        console.log(divleft)
        $("#imgListDiv1").animate({left: divleft+'px'});
        // $('#imgListDiv1').css('left', divleft + 'px');
      }
    }
    
    // gotoAbout(topath){
    //   var curPath = this.$route.path;
    //   console.log('curpath:' + curPath)
    //   window.scroll(0,0)
    //   if(curPath == '/home' || curPath == '/'){
    //     this.$router.push({path: topath}).then(()=>{
    //       location.reload()
    //     })
    //   }else{
    //     this.$router.push({path: topath})
    //   }
      
    // }
  }
}

</script>