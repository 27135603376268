import axios from '@/utils/request'

const api = {
  home: '/home',
  news: '/news/list',
  newsinfo:'/news/info',
  notice: '/notice/list',
  noticeinfo:'/notice/info',
  cases: '/cases/list',
  casesinfo:'/cases/info',
  posts: '/posts/list',
  postsinfo:'/posts/info',
  pageinfo:'/page/info',
  setting:'/setting/detail'
}

export function home (params) {
  return axios({
    url: api.home,
    method: 'get',
    params
  })
}

export function setting (params) {
  return axios({
    url: api.setting,
    method: 'get',
    params
  })
}

export function pageinfo (params) {
  return axios({
    url: api.pageinfo,
    method: 'get',
    params
  })
}

export function news (params) {
  return axios({
    url: api.news,
    method: 'get',
    params
  })
}

export function newsinfo (params) {
  return axios({
    url: api.newsinfo,
    method: 'get',
    params
  })
}

export function cases (params) {
  return axios({
    url: api.cases,
    method: 'get',
    params
  })
}

export function casesinfo (params) {
  return axios({
    url: api.casesinfo,
    method: 'get',
    params
  })
}

export function posts (params) {
  return axios({
    url: api.posts,
    method: 'get',
    params
  })
}

export function postsinfo (params) {
  return axios({
    url: api.postsinfo,
    method: 'get',
    params
  })
}

export function notice (params) {
  return axios({
    url: api.notice,
    method: 'get',
    params
  })
}

export function noticeinfo (params) {
  return axios({
    url: api.noticeinfo,
    method: 'get',
    params
  })
}


