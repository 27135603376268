import {createRouter, createWebHashHistory} from "vue-router";

const routes = [
  {path: "/", redirect: "/home"},
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Index.vue')
  },
  {
    path: '/jgyl',
    name: 'jgyl',
    
    component: () => import('@/views/page/YangLao.vue')
  },
  {
    path: '/sqyl',
    name: 'sqyl',
    component: () => import('@/views/page/Sqyl.vue')
  },
  {
    path: '/jjyl',
    name: 'jjyl',
    component: () => import('@/views/page/Jjyl.vue')
  },
  {
    path: '/honor',
    name: 'Honor',
    component: () => import('@/views/page/Honor.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/page/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/page/Contact.vue')
  },
  {
    path: '/news/:cid',
    name: 'News',
    component: () => import('@/views/news/Index.vue')
  },
  {
    path: '/newsinfo/:id',
    name: 'NewsDetail',
    component: () => import('@/views/news/Detail.vue')
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('@/views/notice/Index.vue')
  },
  {
    path: '/notice/:id',
    name: 'NoticeDetail',
    component: () => import('@/views/notice/Detail.vue')
  },
  {
    path: '/cases/:cid',
    name: 'Cases',
    component: () => import('@/views/cases/Index.vue')
  },
  {
    path: '/casesinfo/:id',
    name: 'CasesDetail',
    component: () => import('@/views/cases/Detail.vue')
  },
  {
    path: '/posts',
    name: 'Posts',
    component: () => import('@/views/posts/Index.vue')
  },
  {
    path: '/cases/:id',
    name: 'PostsDetail',
    component: () => import('@/views/posts/Detail.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})

export default router