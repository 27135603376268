<template>
<header>
  <div class="header_content clearfix">
     <a href="/" class="logo"></a>
    <div class="main_nav clearfix">
      <ul class="list">
        <li class="item"><a @click="gotoAbout('/')">首&nbsp;&nbsp;页</a></li>
        <li class="item">服务专区</li>
        <li class="item">新闻资讯</li>
        <li class="item">项目展示</li>
        <li class="item"><a @click="gotoAbout('/posts')">养老科普</a></li>
        <li class="item">关于我们</li>
      </ul>
      <div class="common_nav">
        <div class="nav_section"></div>
         <!-- 服务专区 -->
        <div class="nav_section">
          <div class="nav_section_con clearfix">
            <div class="common_nav_tit fl">
              服务专区
              <span>BUSINESS SECTION</span>
            </div>
            <div class="common_nav_list fl clearfix nav_detail nav_server">
               <dl class="choose">
                <a @click="gotoAbout('/jgyl')">
                  <img src="/static/img/icon_nurse.png" alt="">
                  <img class="choose_img" src="/static/img/icon_nurse2.png" alt="">
                  <dd>机构养老</dd>
                </a>
                <a @click="gotoAbout('/sqyl')">
                  <img src="/static/img/icon_hospital.png" alt="">
                  <img class="choose_img" src="/static/img/icon_hospital2.png" alt="">
                  <dd>社区养老</dd>
                </a>
                 <a @click="gotoAbout('/jjyl')" >
                  <img src="/static/img/icon_hospital.png" alt="">
                  <img class="choose_img" src="/static/img/icon_hospital2.png" alt="">
                  <dd>居家养老</dd>
                </a>
              </dl>
            </div>
          </div>
          <div class="nav_bd"></div>
        </div>
        
        <!-- 新闻资讯 -->
        <div class="nav_section" style="display: block;">
          <div class="nav_section_con clearfixs">
            <div class="common_nav_tit fl">
              新闻资讯
              <span>NEWS CENTER</span>
            </div>
            <div class="common_nav_list fl clearfix nav_detail nav_server">
              <dl class="choose">
                 <a @click="gotoAbout('/news/1')">
                  <img src="/static/img/icon_journalism.png" alt="">
                  <img class="choose_img" src="/static/img/icon_journalism2.png" alt="">
                  <dd>颐悦要闻</dd>
                </a>
                <a @click="gotoAbout('/news/5')">
                  <img src="/static/img/icon_only.png" alt="">
                  <img class="choose_img" src="/static/img/icon_only2.png" alt="">
                  <dd>公司动态</dd>
                </a>
                <a @click="gotoAbout('/news/6')">
                  <img src="/static/img/icon_occupation.png" alt="">
                  <img class="choose_img" src="/static/img/icon_occupation2.png" alt="">
                  <dd>行业资讯</dd>
                </a>
                <a @click="gotoAbout('/notice')">
                  <img src="/static/img/icon_announcement.png" alt="">
                  <img class="choose_img" src="/static/img/icon_announcement2.png" alt="">
                  <dd>公示公告</dd>
                </a>
              </dl>
            </div>
          </div>
          <div class="nav_bd"></div>
        </div>
        <div class="nav_section">
          <div class="nav_section_con clearfixs">
            <div class="common_nav_tit fl">
              项目展示
              <span>CASES</span>
            </div>
            <div class="common_nav_list fl clearfix nav_detail nav_server">
              <dl class="choose">
                 <a @click="gotoAbout('/cases/4')">
                  <img src="/static/img/icon_health.png" alt="">
                  <img class="choose_img" src="/static/img/icon_health2.png" alt="">
                  <dd>机构养老</dd>
                </a>
                <a @click="gotoAbout('/cases/7')">
                  <img src="/static/img/icon_health_insurance.png" alt="">
                  <img class="choose_img" src="/static/img/icon_health_insurance2.png" alt="">
                  <dd>日间照料中心</dd>
                </a>
                
              </dl>
            </div>
          </div>
          <div class="nav_bd"></div>
        </div>
        <div class="nav_section"></div>
        <!-- 关于我们 -->
        <div class="nav_section" style="display: block;">
          <div class="nav_section_con clearfixs">
            <div class="common_nav_tit fl">
              关于我们
              <span>ABOUT US</span>
            </div>
            <div class="common_nav_list fl clearfix nav_detail nav_server">
              <dl class="choose">
                 <a @click="gotoAbout('/about')">
                  <img src="/static/img/icon_synopsis.png" alt="">
                  <img class="choose_img" src="/static/img/icon_synopsis2.png" alt="">
                  <dd>公司简介</dd>
                </a>
                <a @click="gotoAbout('/honor')">
                  <img src="/static/img/icon_honor.png" alt="">
                  <img class="choose_img" src="/static/img/icon_honor2.png" alt="">
                  <dd>公司荣誉</dd>
                </a>
                <a @click="gotoAbout('/contact')">
                  <img src="/static/img/icon_relation.png" alt="">
                  <img class="choose_img" src="/static/img/icon_relation2.png" alt="">
                  <dd>联系我们</dd>
                </a>
              </dl>
            </div>
          </div>
          <div class="nav_bd"></div>
        </div>
      </div>
    </div>
  </div>
</header>
</template>

<script>
export default{
  methods:{
    // gotoAbout(topath){
    //   //console.log('about click')
    //   var curPath = this.$route.path;
    //   console.log('curpath:' + curPath)

    //   if(curPath == '/home' || curPath == '/' || topath == "/" ){
    //     this.$router.push({path: topath}).then(()=>{
    //       location.reload()
    //     })
    //   }else{
    //     this.$router.push({path: topath})
    //   }
      
    // }
  }
}
</script>