<script setup>
import HeaderPage from './components/Header.vue'
import FooterPage from './components/Footer.vue'
import IndexPage from './views/Index.vue'

let curPath = window.location.href.split("#")[1]
const home = curPath == '/' || curPath == '/home'
</script>

<template>
  <HeaderPage />
  <!-- <IndexPage v-if="home"/> -->
  <!-- 路由出口 -->
  <!-- 路由匹配到的组件将渲染在这里 -->
  <router-view ></router-view>

  <FooterPage />
</template>
