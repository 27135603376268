// import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import Axios from 'axios';

var app = createApp(App)
app.config.globalProperties.Axios = Axios

// 定义全局方法
const gotoAbout = function(topath) {
    var curPath = this.$route.path;
      console.log('curpath:' + curPath)
      window.scroll(0,0)
      if(curPath == '/home' || curPath == '/' || topath == "/" || topath.startsWith('/news') || topath.startsWith('/cases') || topath == "/notice" || topath == "/about"){
        this.$router.push({path: topath}).then(()=>{
          location.reload()
        })
      }else{
        this.$router.push({path: topath})
      }
}

app.config.globalProperties.gotoAbout = gotoAbout

app.use(router).mount('#app')